export const Tempus = () => ({
  showIssue() {
    return (this.issueSelectedData.id && true) || false
  },

  getIssueValue(key) {
    if (!this.issueSelectedData.hasOwnProperty("key")) {
      return ""
    }
    switch (key) {
      case "key":
        return this.issueSelectedData.key
      case "summary":
        return this.issueSelectedData.fields.summary
      case "timespent":
        return this.issueSelectedData.fields.timespent
      case "labels_length":
        return this.issueSelectedData.fields.labels.length
      case "labels":
        return this.issueSelectedData.fields.labels.join(", ")
      case "activity_exists":
        return (
          this.issueSelectedData.fields.hasOwnProperty("customfield_10044") &&
          this.issueSelectedData.fields.customfield_10044 != null
        )
      case "activity":
        return this.issueSelectedData.fields.hasOwnProperty(
          "customfield_10044"
        ) && this.issueSelectedData.fields.customfield_10044 != null
          ? this.issueSelectedData.fields.customfield_10044.value
          : ""
      case "customer_exists":
        return (
          this.issueSelectedData.fields.hasOwnProperty("customfield_10068") &&
          this.issueSelectedData.fields.customfield_10068 != null
        )
      case "customer":
        return this.issueSelectedData.fields.hasOwnProperty(
          "customfield_10068"
        ) && this.issueSelectedData.fields.customfield_10068 != null
          ? this.issueSelectedData.fields.customfield_10068.value
          : ""
      case "status_name":
        return [
          this.issueSelectedData.fields.status.name,
          this.issueSelectedData.fields.status.id
        ]
      case "transitions":
        let transitions =
          this.issueSelectedData.hasOwnProperty("transitions") &&
          this.issueSelectedData.transitions != null
            ? this.issueSelectedData.transitions.filter((option) => {
                return option.to.id != this.issueSelectedData.fields.status.id
              })
            : []
        const extraTransition = [{ id: 0, name: "Aktion auswählen" }]
        return extraTransition.concat(transitions)
      case "aggregatetimeoriginalestimate":
        return this.issueSelectedData.fields.hasOwnProperty(
          "aggregatetimeoriginalestimate"
        )
          ? this.issueSelectedData.fields.aggregatetimeoriginalestimate
          : 0
      default:
        return ""
    }
  },

  itemInActiveList(type, key) {
    return (
      this.itemlistsActive[type] && this.itemlistsActive[type].includes(key)
    )
  },

  getIssueSearchstring(issue) {
    let issueSearch = issue.fields.labels.join(", ")
    issueSearch += ", " + issue.fields.status.name
    if ("parent" in issue.fields) {
      issueSearch += ", " + issue.fields.parent.key
    }
    return issueSearch
  },

  focusCombobox(event, type) {
    const inputElement = document.getElementById("combobox-" + type)
    if (event.type == "focus" || event.type == "click") {
      // empty input field
      inputElement.value = ""
      // close is false
      return false
    } else if (
      document.activeElement != event.target &&
      event.type == "keyup" &&
      (event.code == "Tab" || event.code == "Enter")
    ) {
      return true
    }
    // on change
    if (event.type == "keyup" && event.code != "Tab") {
      const query = inputElement.value
      const itemlist = this.itemlists[type]
      const result =
        query === ""
          ? itemlist
          : itemlist.filter((option) => {
              const haystack = document.getElementById(option).dataset.name
              return haystack.toLowerCase().includes(query.toLowerCase())
            })
      this.itemlistsActive[type] = result
      // not finished yet
      if (event.code != "Enter" && event.code != "ArrowDown") {
        return false
        // finished. fetch project
      } else if (event.code == "Enter" && result && result.length == 1) {
        this.comboboxProxy(type, result[0])
        // close
        return true
        // switch to arrows, go in list
      } else if (event.code == "ArrowDown" && result && result.length > 0) {
        const element = document.getElementById(result[0])
        element.focus()
        // not closed
        return false
      }
    }
  },

  comboboxProxy(type, key) {
    if (type === "projects") {
      this.setProject(key)
    } else if (type === "issues") {
      this.setIssue(key)
    } else if (type === "timesheets") {
      this.setTimesheet(key)
    } else if (type === "users") {
      this.setUserSelected(key)
    } else if (type === "customerOptions") {
      this.setCustomerSelected(key)
    }
    return true
  },

  toggleSelectedWorklogsForCharge() {
    const checkboxes = document.querySelectorAll(
      'input[name="worklogToCharge[]"]'
    )
    const issueMasterCheckboxes = document.querySelectorAll(
      'input[name="issueMaster"]'
    )
    issueMasterCheckboxes.forEach((chbx) => {
      chbx.checked = this.toggleCheckboxesForCharge
    })
    checkboxes.forEach((chbx) => {
      chbx.checked = this.toggleCheckboxesForCharge
    })
    this.toggleCheckboxesForCharge = !this.toggleCheckboxesForCharge
  },

  getProjectFromIssueKey(issueKey) {
    const projectKey = issueKey.substring(0, issueKey.indexOf("-"))
    return this.projects.hasOwnProperty(projectKey)
      ? this.projects[projectKey].name
      : ""
  },

  getCustomerFromIssue(issue) {
    return issue.fields.hasOwnProperty(this.customerCustomfield) &&
      issue.fields[this.customerCustomfield] != null
      ? issue.fields[this.customerCustomfield].value + "<br>"
      : ""
  },

  copyCsv(issues) {
    const csvRows = [
      [
        "Projekt-Name",
        "Kunde",
        "Eltern-Key",
        "Eltern-Name",
        "Eltern-Typ",
        "Todo-Key",
        "Todo-Name",
        "Tätigkeit",
        "Kommentar",
        "Agent:in",
        "Start-Tag",
        "Start-Zeit",
        "Minuten",
        "Abgerechnet"
      ]
    ]
    const projects = []
    const issuesToCopy = JSON.parse(JSON.stringify(issues))
    issuesToCopy.sort((a, b) => {
      if (!("parent" in a.fields)) {
        return -1
      } else if (!("parent" in b.fields)) {
        return 1
      }
      return (a.fields.parent.key > b.fields.parent.key && -1) || 1
    })
    issuesToCopy.forEach((issue) => {
      const issueKey = issue.key
      const projectKey = issueKey.substring(0, issueKey.indexOf("-"))
      const projectName = this.projects.hasOwnProperty(projectKey)
        ? this.projects[projectKey].name
        : ""
      const worklogs = issue.worklogs
      let parentKey = "-"
      let parentSummary = "-"
      let parentType = "-"
      if ("parent" in issue.fields) {
        parentKey = issue.fields.parent.key
        parentSummary = issue.fields.parent.fields.summary
        parentType = issue.fields.parent.fields.issuetype.name
      }
      const activity =
        issue.fields.hasOwnProperty("customfield_10044") &&
        issue.fields.customfield_10044 != null
          ? issue.fields.customfield_10044.value
          : ""
      const customerName =
        issue.fields.hasOwnProperty(this.customerCustomfield) &&
        issue.fields[this.customerCustomfield] != null
          ? issue.fields[this.customerCustomfield].value
          : ""
      worklogs.forEach((worklog) => {
        const row = [
          projectName,
          customerName,
          parentKey,
          parentSummary,
          parentType,
          issueKey,
          issue.fields.summary,
          activity,
          this.getWorklogComment(worklog),
          worklog.author.displayName,
          this.formatDateOnly(worklog.started),
          this.formatTimeOnly(worklog.started),
          worklog.timeSpentSeconds / 60,
          this.getWorklogProperty(worklog, "charged", 0) > 0
            ? "abgerechnet"
            : "nicht abgerechnet"
        ]
        csvRows.push(row)
      })
    })
    const csvData = csvRows.map((row) => row.join("\t")).join("\n")
    this.$refs.exportcsv.value = csvData
    navigator.clipboard.writeText(this.$refs.exportcsv.value)
  }
})
